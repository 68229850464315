.project {
  min-height: 500px;
  width: 400px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.2);
}

.project-image-container {
  height: 50%;
  width: 100%;
  margin: 0;
}

.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.project-title {
  font-size: 22px;
  font-family: 'Lato', sans-serif;
  padding: 0 20px;
  background: linear-gradient(to right, #8d8dda, #abd9d9);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.project-description {
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  color: white;
  padding: 0 20px;
  font-weight: 300;
}

.project-links {
  display: flex;
  justify-content: center;
}

.project-link {
  cursor: pointer;
}

.link-img {
  height: 40px;
  width: 40px;
  margin: 0 10px;
  color: white;
}

/* media queries */
@media (max-width: 1311px) {
  .project {
    width: 300px;
  }
}
