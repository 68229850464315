.hero-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 0 30px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
}

.hero-section {
  z-index: 10;
  overflow: hidden;
}

.headshot {
  margin: 0;
  width: 400px;
  height: auto;
  z-index: 10;
}

.hero-title {
  position: relative;
  z-index: 20;
  margin-top: -80px;
  margin-bottom: 5px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  letter-spacing: 5px;
  font-size: 80px;
  background: linear-gradient(to right, #abd9d9, #8d8dda);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.hero-subtitle {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: #abd9d9;
  font-weight: 500;
  font-size: 22px;
}

.social-logos {
  position: absolute;
  left: 25vw;
  height: 200px;
  opacity: 0.3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 999;
}

.github-logo,
.linkedin-logo {
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
}

/* media queries */
@media (max-width: 932px) {
  .social-logos {
    left: 20vw;
  }
}

@media (max-width: 797px) {
  .hero-title {
    font-size: 60px;
    margin-top: -70px;
  }
  .hero-subtitle {
    font-size: 18px;
  }
  .headshot {
    width: 300px;
  }
}

@media (max-width: 632px) {
  .github-logo,
  .linkedin-logo {
    width: 20px;
    height: 20px;
  }
  .hero-title {
    font-size: 50px;
    margin-top: -55px;
  }
  .hero-subtitle {
    font-size: 16px;
  }
}

@media (max-width: 612px) {
  .social-logos {
    display: none;
  }
}

@media (max-width: 548px) {
  .hero-title {
    font-size: 40px;
    margin-top: -45px;
  }
  .hero-subtitle {
    font-size: 14px;
  }
}

@media (max-width: 465px) {
  .hero-title {
    font-size: 35px;
    margin-top: -35px;
  }
  .hero-subtitle {
    font-size: 12px;
  }
}

@media (max-width: 425px) {
  .hero-title {
    font-size: 30px;
    margin-top: -35px;
  }
}

@media (max-width: 382px) {
  .hero-title {
    font-size: 25px;
    margin-top: -35px;
  }
}

@media (max-width: 340px) {
  .hero-title {
    font-size: 22px;
  }
}
