.App {
  background-color: #000222;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-family: 'Lato', sans-serif;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.loading-screen {
  z-index: 0;
}

.nav {
  position: absolute;
  top: 0;
  z-index: 10;
}

.purple-blur,
.teal-blur {
  border-radius: 50%;
  filter: blur(150px);
}

.purple-blur {
  height: 600px;
  width: 700px;
  position: absolute;
  top: 5vh;
  left: 20vw;
  background-color: #8d8dda;
}

.teal-blur {
  height: 300px;
  width: 350px;
  position: absolute;
  top: 30vh;
  right: 20vw;
  background-color: #abd9d9;
}

.background-circle,
.background-square {
  height: 300px;
  width: 300px;
  position: absolute;
  z-index: 0;
}

.background-square {
  transform: rotate(45deg);
  top: 125px;
  right: -200px;
  border: 1px solid rgba(171, 217, 217, 0.4);
}

.background-circle {
  border-radius: 50%;
  top: 600px;
  left: -100px;
  border: 1px solid rgba(171, 217, 217, 0.4);
  z-index: 0;
}

.projects {
  margin-bottom: 20px;
  z-index: 999;
}

/* media queries */
@media (max-width: 789px) {
  .teal-blur {
    height: 200px;
    width: 250px;
    top: 50vh;
  }
  .purple-blur {
    height: 400px;
    width: 500px;
    top: 2vh;
    right: 15vw;
  }
}

@media (max-width: 632px) {
  .teal-blur {
    height: 150px;
    width: 200px;
    top: 60vh;
  }
  .purple-blur {
    height: 300px;
    width: 400px;
    top: 5vh;
    left: 10vw;
  }
}

@media (max-width: 612px) {
  .background-circle,
  .background-square {
    height: 250px;
    width: 250px;
  }
  .background-square {
    top: 0;
  }
}
