.hamburger-container,
.menu {
  display: flex;
  position: fixed;
}

.hamburger,
.mobile-nav-link {
  cursor: pointer;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.hamburger-container {
  top: 15px;
  left: 10px;
  z-index: 1001;
  cursor: pointer;
  height: 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
}

.hamburger {
  width: 30px;
  height: 30px;
}

.hamburger-div {
  width: 25px;
  height: 2px;
  border-radius: 25px;
  background-color: #fff;
  margin-bottom: 5px;
}

.menu {
  list-style-type: none;
  background-color: #002;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  top: 0;
  left: 0;
}

.menu li {
  font-size: 22px;
  font-weight: 500;
}

.mobile-social-icon {
  height: 40px;
  width: 40px;
  margin: 0 20px;
}

@media (min-width: 613px) {
  .mobile-nav {
    display: none;
  }
}
