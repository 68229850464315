.contact {
  width: 100vw;
  padding: 0 60px;
  box-sizing: border-box;
}

.contact-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.contact-title {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0;
  background: linear-gradient(to right, #abd9d9, #8d8dda);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-info,
.contact-form {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-info {
  justify-content: space-between;
}

.contact-info-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-availability {
  text-align: justify;
  line-height: 1.2;
}

.contact-subtitle {
  font-size: 24px;
  font-weight: 400;
}

.contact-availability {
  font-size: 16px;
  font-weight: 300;
  width: 75%;
}

.contact-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-link-icon {
  width: 25px;
  height: 25px;
  color: white;
  margin: 10px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
textarea,
.form-submit-button {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: white;
  width: 90%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
}

input::placeholder,
textarea::placeholder,
.form-submit-button::placeholder {
  color: white;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  font-weight: 300;
}

.form-submit-button {
  width: 95%;
  box-sizing: border-box;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.error-message {
  font-size: 14px;
  text-align: center;
}

/* media queries */
@media (max-width: 741px) {
  .contact-title {
    text-align: center;
  }

  .contact-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-info,
  .contact-form {
    width: 90%;
  }
}
