.skills {
  display: flex;
  flex-direction: column;
  width: 100vw;
  box-sizing: border-box;
  padding: 0 50px;
}

.skills-title {
  font-family: 'Lato', sans-serif;
  background: linear-gradient(to right, #abd9d9, #8d8dda);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
  font-weight: 700;
  margin: 20px 0;
  align-self: flex-start;
}

.skills-container {
  display: flex;
  justify-content: space-around;
  height: auto;
  width: 75vw;
  flex-wrap: wrap;
  align-self: center;
  justify-self: center;
}

/* media queries */
@media screen and (max-width: 741px) {
  .skills-title {
    text-align: center;
    align-self: center;
  }
}
