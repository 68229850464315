.footer {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-text {
    font-size: 14px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
}