.nav {
  box-sizing: border-box;
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-top: 10px;
}

.logo-name {
  font-size: 18px;
  font-weight: 500;
}

.nav-links {
  display: flex;
  justify-content: space-between;
  width: 20%;
  list-style-type: none;
  font-weight: 300;
  font-size: 18px;
}

.nav-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.resume-button {
  background-color: rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

a {
  text-decoration: none;
}

/* Media queries */
@media (max-width: 789px) {
  .nav-links {
    width: 35%;
  }
}
@media (max-width: 704px) {
  .nav-links {
    width: 30%;
  }
}

@media (max-width: 612px) {
  .nav {
    display: none;
  }
}
